import { isPaytmBrowser } from '../utilities';
import { saveLoginDetails } from './logInUtilities';
import { paytmTokenFromAuthId } from '../_services/api/paytmTokenFromAuthId';

export const paytmAsyncFun = (fetchedAuthyId) => new Promise((resolve, reject) => {
    if (!isPaytmBrowser()) throw { error: 'Paytm not found' };
    paytmTokenFromAuthId(fetchedAuthyId).subscribe(
        (res) => {
            //  alert("in paytmLogin page"+JSON.stringify(res));
            saveLoginDetails({ ...res, is_logged_in: true });
            resolve();
        }, (err) => {
          reject(err);
        },
      );
});


