import { randomGenerator } from '../../utilities';
import { getTruecallerUserApi } from '../api/truecaller/getTruecallerUserApi';

const polling = async (requestId) => {

};

function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
    // iPad on isIOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

const getAppkeyFromHostName = (host) => {
  switch (host) {
    case 'gmaccessory.in':
      return 'Hkyn0bdec6be2e3c44e7ea2086b1eab6f704b';
    case 'accessories.gomechanic.in':
      return 'q14QO76fb85baf060445db26dbfb85749d88c';
    case 'accessorieshome.in':
      return '47ks10dfe81661cca4772b03aeb02f0443412';
    case 'vehicleaccessories.in':
      return 'CMpMa475bee54dad94d35b2d394f6d9849d3c';
    case 'gomechanic.in':
      return 'qGEvHcbf9b7e97eab458a93d12a964cceb936';
    default:
      return 'qGEvHcbf9b7e97eab458a93d12a964cceb936';
  }
};

export const initiateTruecaller = (setShowWaitLoader) => {
  const requestId = randomGenerator(25);
  const hostName = window && window.location ? window.location.hostname : 'gomechanic.in';
  // const appKey = 'qGEvHcbf9b7e97eab458a93d12a964cceb936';
  const appKey = getAppkeyFromHostName(hostName);
  const appName = 'Gomechanic - Web';

  return new Promise((resolve, reject) => {
    if (isIOS()) {
      reject(new Error('IOS device found. Disabled truecaller'));
      return;
    }
    // `${r.b.truecallerAPIUrl}&requestNonce=${i}&lang=${r.b.langSiteArray.language}&partnerKey=${r.b.trueCallerApiKey}&type=btmsheet&\n            ctaTextColor=white&loginPrefix=proceed&loginSuffix=verifymobile&privacyUrl=${o}&termsUrl=${o}&ctaPrefix=use&btnShape=rect`,
    // window.location.href = `truecallersdk://truesdk/web_verify?requestNonce=${requestId}&partnerKey=${appKey}&partnerName=${appName}&lang=EN&title=GoMech`;

    // window.location.href = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${requestId}&partnerKey=${appKey}&partnerName=${appName}&lang=EN&privacyUrl=http://gomechanic.in/privacy&termsUrl=https://gomechanic.in/terms&loginPrefix=continue&loginSuffix=login&ctaPrefix=continuewith&ctaColor=%23e73c33&ctaTextColor=%23ffffff&btnShape=round&title=GoMech`;
    window.location.href = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${requestId}&partnerKey=${appKey}&partnerName=${appName}&lang=EN&privacyUrl=http://${hostName}/privacy&termsUrl=https://${hostName}/terms&loginPrefix=continue&loginSuffix=login&ctaPrefix=continuewith&ctaColor=%23e73c33&ctaTextColor=%23ffffff&btnShape=round&title=GoMech`;

    // setTimeout(async () => {
    //   if (document.hasFocus()) {
    //     // Truecaller app not present on the device and you redirect the user
    //     // to your alternate verification page
    //     reject(requestId);
    //   } else {
    //     // Truecaller app present on the device and the profile overlay opens
    //     // The user clicks on verify & you'll receive the user's
    //     // access token to fetch the profile on your
    //     // callback URL - post which, you can refresh the session at your
    //     // frontend and complete the user  verification
    //
    //     // resolve('Start polling : ', requestId);
    //     getTruecallerUserApi(requestId).subscribe(
    //       (res) => {
    //         if (!res.userConsent) {
    //           reject(requestId);
    //           return;
    //         }
    //         if (res.userConsent && res.data) {
    //           resolve(JSON.parse(res.data));
    //         }
    //       }, (err) => {
    //         reject(requestId);
    //       },
    //     );
    //   }
    // }, 600);
    window.onfocus = () => {
      if (setShowWaitLoader) {
        setShowWaitLoader(true);
      }
      getTruecallerUserApi(requestId).subscribe(
        (res) => {
          if (!res.userConsent) {
            setShowWaitLoader(false);
            reject(requestId);
            return;
          }
          if (res.userConsent && res.data) {
            resolve(JSON.parse(res.data));
          }
        }, (err) => {
          setShowWaitLoader(false);
          reject(requestId);
        },
      );
    };
  });
};
