import { phonePeTokenFromGrantToken } from '../_services/api/phonePeTokenFromGrantToken';
import { saveLoginDetails } from './logInUtilities';
import { isPhonePeBrowser } from '../utilities';

export const phonepeAsyncFun = () => new Promise((resolve, reject) => {
  // console.log({ ok: 'here' });
  const { PhonePe } = window;
  if (!PhonePe || !isPhonePeBrowser()) throw { error: 'PhonePe not found' };
  PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then((sdk) => {
    sdk.fetchAuthToken().then((res) => {
      // alert(`Grant token data received = ${JSON.stringify(res)}`);
      // // alert(res);
      phonePeTokenFromGrantToken(res.grantToken,
        btoa(JSON.stringify({ grantToken: res.grantToken }))).subscribe(
        (res) => {
          saveLoginDetails({ ...res, is_logged_in: true });
          resolve();
          // alert(`response from our server ${JSON.stringify(res)}`);
        }, (err) => {
          // alert(JSON.stringify(err)); history.replace(`/login?return=${window.location.pathname}`);
          reject(err);
        },
      );
    }).catch((err) => {
      reject(err);
      // alert(`Error occurred while fetching the grant token: ${err}`);
      // history.replace(`/login?return=${window.location.pathname}`);
      // alert(err);
    });
  });
});
