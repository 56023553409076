import React from 'react';
import Layout from '../../../components/Layout';
import LoginPage from '../../../components/LoginPage';
import RetailLoginPopup from '../../../components/RetailLoginPopup';

const title = 'login';

function action(context) {
  return {
    chunks: ['login'],
    title,
    component: (
      <Layout
        customHeader={{
          mainHeader: false,
        }}
      >
        {/* <LoginPage /> */}
        <RetailLoginPopup hide={() => false} context={context}/>
      </Layout>
    ),
  };
}

export default action;
