import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const phonePeTokenFromGrantToken = (grantToken, encoded) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `/api/v1/get-phone-pe-token/?grantToken=${grantToken}&encoded=${encoded}`;
  return HttpHandler.get(url, headers);
};
