import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { BeatLoader } from 'react-spinners';
import s from './css/style.css';
import LoginUpperBar from './LoginUpperBar';
import Toast from '../Toast/Toast';
import { getQueryStringValue, randomGenerator, isMalaysiaRequest } from '../../utilities';
import history from '../../history';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { phonepeAsyncFun } from '../../logInUtilities/phonePeLogin';
import { paytmAsyncFun } from '../../logInUtilities/paytmLogin';
import Global from '../../global';
import LoginSectionDesktop from '../DesktopRightSideBar/LoginSectionDesktop/LoginSectionDesktop';
import autoUtmHistory from '../../_services/autoUtmHistory';
import { initiateTruecaller } from '../../_services/truecaller/initiate';
import { saveLoginDetails } from '../../logInUtilities/logInUtilities';
import Modal from '../Modal/Modal';
import { loginGtmEvent } from '../../gtm/gtmServices';

const steps = {
  PHONE_NUMBER_SCREEN: 1,
  OTP_SCREEN: 2,
  ENTER_DETAILS_SCREEN: 3,
};

const OTP_INPUTS = [null, null, null, null];

const LoginPage = (props) => {
  useStyles(s);

  const [paytmSettled, setPaytmSettled] = useState(!props.isPaytmRequest);
  const [phonePeSettled, setPhonePeSettled] = useState(!props.isPhonePeRequest);

  useEffect(
    () => {
      if (props.isPaytmRequest) {
        const ApiManager = new Global();
        function ready(callback) {
          // call if jsbridge is injected
          if (window.JSBridge) {
            callback && callback();
          } else { // listen to jsbridge ready event
            document.addEventListener('JSBridgeReady', callback, false);
          }
        }

        ready(() => {
          JSBridge.call('paytmFetchAuthCode', { clientId: ApiManager.urls.paytmClientId },
            (result) => {
              if (result.error) {
                // setPaytmSettled(true);
                // alert(getQueryStringValue('return'));
                history.replace(getQueryStringValue('return'));
              } else {
                const fetchedAuthyId = result.data.authId;
                paytmAsyncFun(fetchedAuthyId).then(
                  (res) => {
                    // alert("finally"+JSON.stringify(res));
                    const redirectType = getQueryStringValue('type');
                    if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
                      if (redirectType === 'hard') {
                        window.location.replace(getQueryStringValue('return'));
                      } else history.replace(getQueryStringValue('return'));
                    }
                  },
                ).catch(
                  (err) => {
                    console.log(err);
                  },
                );
              }
            });
        });
      }
    },
    [],
  );

  const [showWaitLoader, setShowWaitLoader] = useState(false);

  // var [maxLength, setMaxLength] = useState(props.isNigerianRequest ? 11 : 10);
  useEffect(
    () => {
      // console.log(props.isPhonePeRequest, 'phone pe request');
      if (props.isPhonePeRequest) {
        phonepeAsyncFun().then(
          (res) => {
            // setLoading(false);
            // setPhonePeSettled(true);
            const redirectType = getQueryStringValue('type');
            if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
              if (redirectType === 'hard') {
                window.location.replace(getQueryStringValue('return'));
              } else history.replace(getQueryStringValue('return'));
            }
          },
        ).catch(
          (err) => {
            setPhonePeSettled(true);
            // history.replace(`/login?return=${window.location.pathname}`);
          },
        );
      } else if(!props.isMalaysiaRequest) {
        (() => {
           (setShowWaitLoader).then(
            (res) => {
              window.onfocus = () => {};
              saveLoginDetails({ ...res.data, is_logged_in: true });
              if (getQueryStringValue('return').includes('accessories')) {
                loginGtmEvent({
                  phoneNumber: res.data.mobile, success: true, fire_screen: 'header', user_id: res.data.id, truecaller: true,
                });
              }
              const redirectType = getQueryStringValue('type');
              if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
                if (redirectType === 'hard') {
                  window.location.replace(getQueryStringValue('return'));
                } else autoUtmHistory(history).replace(getQueryStringValue('return'));
              } else window.location.replace('/');
            },
          ).catch(
            () => {
              window.onfocus = () => {};
              setShowWaitLoader(false);
            },
          );
        })();
      }
    },
    [],
  );


  const otpChange = (e, index) => {
    if (!isNaN(e.target.value) && e.target.value.length === 1 && index !== 3) {
      OTP_INPUTS[parseInt(index, 10) + 1].focus();
    }
  };

  const [step, setStep] = useState(steps.PHONE_NUMBER_SCREEN);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [resendingOtp, setResendingOtp] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1500,
  });

  useEffect(() => {
    if (step === steps.OTP_SCREEN) if (OTP_INPUTS[0]) OTP_INPUTS[0].focus();
  }, [step]);

  // const onClickHandlerGenerator = () => {
  //   if (step === steps.PHONE_NUMBER_SCREEN) {
  //     const functionToRun = () => {
  //       if (!isNaN(phoneNumber) && `${phoneNumber}`.length === 10) {
  //         sendOtp(phoneNumber).subscribe(
  //           (res) => {
  //             if (res.status === true) {
  //               setToastConfig({
  //                 ...toastConfig,
  //                 show: true,
  //                 text: `OTP sent to ${phoneNumber}`,
  //                 showTick: true,
  //                 time: 1500,
  //               });
  //             }
  //             setStep(steps.OTP_SCREEN);
  //           },
  //           (error) => console.exception,
  //         );
  //       } else {
  //         setToastConfig({
  //           ...toastConfig,
  //           show: true,
  //           text: 'Invalid Phone Number',
  //           showTick: false,
  //         });
  //       }
  //       // return () => setStep(steps.OTP_SCREEN);
  //     };
  //     return functionToRun;
  //   }
  //
  //   if (step === steps.OTP_SCREEN) {
  //     const functionToRun = () => {
  //       const otp = `${OTP_INPUTS[0].value}${OTP_INPUTS[1].value}${OTP_INPUTS[2].value}${OTP_INPUTS[3].value}`;
  //       if (!isNaN(otp) && otp.length === 4) {
  //         verifyOtp(phoneNumber, otp).subscribe((res) => {
  //           if (res.status === true) {
  //             setToastConfig({
  //               ...toastConfig,
  //               show: true,
  //               text: 'OTP verified.',
  //               showTick: true,
  //               time: 1500,
  //             });
  //             setStep(steps.ENTER_DETAILS_SCREEN);
  //             saveLoginDetails({ ...res.data, is_logged_in: true });
  //             const redirectType = getQueryStringValue('type');
  //             if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
  //               if (redirectType === 'hard') {
  //                 window.location.replace(getQueryStringValue('return'));
  //               } else history.replace(getQueryStringValue('return'));
  //             }
  //           } else {
  //             setToastConfig({
  //               ...toastConfig,
  //               show: false,
  //               text: 'OTP verification failed.',
  //               showTick: false,
  //               time: 1500,
  //             });
  //           }
  //         }, console.error);
  //       } else {
  //         setToastConfig({
  //           ...toastConfig,
  //           show: true,
  //           text: 'Invalid OTP',
  //           showTick: false,
  //         });
  //       }
  //     };
  //     return functionToRun;
  //   }
  //
  //   if (step === steps.ENTER_DETAILS_SCREEN) {
  //     return () => {};
  //   }
  //   return () => {};
  // };

  const onLoggedIn = () => {
    const redirectType = getQueryStringValue('type');
    if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
      if (redirectType === 'hard') {
        window.location.replace(getQueryStringValue('return'));
      } else history.replace(getQueryStringValue('return'));
    }
  };

  const backClickHandlerGenerator = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      return () => {};
    }

    if (step === steps.OTP_SCREEN) {
      return () => setStep(steps.PHONE_NUMBER_SCREEN);
    }

    if (step === steps.ENTER_DETAILS_SCREEN) {
      return () => setStep(steps.PHONE_NUMBER_SCREEN);
    }
    return () => {};
  };

  const getUpperBar = () => (
    <>
      {step === steps.PHONE_NUMBER_SCREEN && (
        <LoginUpperBar
          noBackButton
          onBackClick={backClickHandlerGenerator()}
          title="Login"
          subtitle="Enter your phone number to continue"
          logo
        />
      )}

      {step === steps.OTP_SCREEN && (
        <LoginUpperBar
          noBackButton
          onBackClick={backClickHandlerGenerator()}
          title="Verification"
          subtitle={`OTP sent to ${phoneNumber}`}
        />
      )}

      {step === steps.ENTER_DETAILS_SCREEN && (
        <LoginUpperBar
          onBackClick={backClickHandlerGenerator()}
          title="Enter Details"
          subtitle="Enter your name and email"
        />
      )}
    </>
  );

  if (!paytmSettled) return <FullPageLoader />;
  if (!phonePeSettled) return <FullPageLoader />;

  return (
    <>
      {showWaitLoader
      && (
        <Modal
          noPadding
          onOverlayClick={() => {
            // if (props.hide) {
            //   props.hide();
            // }
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            fontWeight: 500,
          }}
          >
            <div style={{
              marginBottom: '50px',
            }}
            >
              {' '}
              Please hold on while we get your details.
            </div>
            <BeatLoader
              size={15}
              color="#e73c33"
            />
          </div>
        </Modal>
      )}
      {getUpperBar(step, phoneNumber)}
      <LoginSectionDesktop
        setPhoneNumber={setPhoneNumber}
        setStep={setStep}
        onLoggedIn={() => {
          onLoggedIn();
        }}
        useFor="checkout"
        beforeSendOtpText="CONTINUE"
        afterSendOtpText="VERIFY OTP"
        setToastConfig={setToastConfig}
        parentStyle={{
          padding: '10px',
        }}
        accessories={getQueryStringValue('return') && getQueryStringValue('return').includes('accessories') ? 'header' : null}
      />

      {/* <form onSubmit={(e) => e.preventDefault()}> */}
      {/*  <div className={s.formContainer}> */}
      {/*    {step === steps.PHONE_NUMBER_SCREEN && ( */}
      {/*      <> */}
      {/*        <div className={s.inputs}> */}
      {/*          <div className={s.phoneNumberContainer}> */}
      {/*            <InputBox */}
      {/*              title="Phone Number" */}
      {/*              customInput={( */}
      {/*                <> */}
      {/*                  <div className={s.phoneNumberInput}> */}
      {/*                    {props.isNigerianRequest ? (<span className={s.text234}>+234 -</span>) */}
      {/*                      : (<span className={s.text91}>+91 -</span>)} */}
      {/*                    <input */}
      {/*                      onChange={(e) => { */}
      {/*                        setPhoneNumber( */}
      {/*                          parseInt(e.target.value, 10) || '', */}
      {/*                        ); */}
      {/*                      }} */}
      {/*                      type="tel" */}
      {/*                      maxLength={10} */}
      {/*                      value={parseInt(phoneNumber, 10) || ''} */}
      {/*                    /> */}
      {/*                  </div> */}
      {/*                </> */}
      {/*              )} */}
      {/*            /> */}
      {/*            <img */}
      {/*              loading="lazy" */}
      {/*              alt="Enter phone number" */}
      {/*              src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/7AD7C070-301F-48D4-838C-BABADAB4E00F.svg" */}
      {/*            /> */}
      {/*          </div> */}
      {/*        </div> */}
      {/*      </> */}
      {/*    )} */}

      {/*    /!* OTP SCREEN -- start *!/ */}
      {/*    {step === steps.OTP_SCREEN && ( */}
      {/*      <> */}
      {/*        <div className={s.inputs}> */}
      {/*          <div className={s.otpContainer}> */}
      {/*            <input */}
      {/*              type="number" */}
      {/*              ref={(el) => (OTP_INPUTS[0] = el)} */}
      {/*              onChange={(e) => otpChange(e, 0)} */}
      {/*              maxLength={1} */}
      {/*              className={s.otpInput} */}
      {/*              onFocus={(e) => e.target.select()} */}
      {/*            /> */}

      {/*            <input */}
      {/*              type="number" */}
      {/*              ref={(el) => (OTP_INPUTS[1] = el)} */}
      {/*              onChange={(e) => otpChange(e, 1)} */}
      {/*              maxLength={1} */}
      {/*              className={s.otpInput} */}
      {/*              onFocus={(e) => e.target.select()} */}
      {/*            /> */}

      {/*            <input */}
      {/*              type="number" */}
      {/*              ref={(el) => (OTP_INPUTS[2] = el)} */}
      {/*              onChange={(e) => otpChange(e, 2)} */}
      {/*              maxLength={1} */}
      {/*              className={s.otpInput} */}
      {/*              onFocus={(e) => e.target.select()} */}
      {/*            /> */}

      {/*            <input */}
      {/*              type="number" */}
      {/*              ref={(el) => (OTP_INPUTS[3] = el)} */}
      {/*              onChange={(e) => otpChange(e, 3)} */}
      {/*              maxLength={1} */}
      {/*              className={s.otpInput} */}
      {/*              onFocus={(e) => e.target.select()} */}
      {/*            /> */}
      {/*          </div> */}
      {/*        </div> */}
      {/*      </> */}
      {/*    )} */}
      {/*    /!* OTP SCREEN -- end *!/ */}

      {/*    {step === steps.ENTER_DETAILS_SCREEN && ( */}
      {/*      <> */}
      {/*        <div className={s.inputs}> */}
      {/*          <InputBox title="Name" /> */}
      {/*        </div> */}

      {/*        <div className={s.inputs}> */}
      {/*          <InputBox title="Email" /> */}
      {/*        </div> */}

      {/*        <div className={s.inputs}> */}
      {/*          <InputBox title="Referral Code (Optional)" /> */}
      {/*        </div> */}
      {/*      </> */}
      {/*    )} */}

      {/*    <div className={s.lowerBar}> */}
      {/*      <div className={s.redBar} onClick={onClickHandlerGenerator(step)}> */}
      {/*        <span> */}
      {/*          {step === steps.PHONE_NUMBER_SCREEN ? 'continue' : null} */}
      {/*          {step === steps.OTP_SCREEN ? 'verify' : null} */}
      {/*          {step === steps.ENTER_DETAILS_SCREEN ? ( */}
      {/*            <Link to="/address">PROCEED</Link> */}
      {/*          ) : null} */}
      {/*        </span> */}
      {/*      </div> */}
      {/*      <div className={s.terms}> */}
      {/*        <span> */}
      {/*          {step === steps.OTP_SCREEN ? ( */}
      {/*            <span onClick={ */}
      {/*              !resendingOtp ? () => { */}
      {/*                if (!isNaN(phoneNumber) && `${phoneNumber}`.length === 10) { */}
      {/*                  setResendingOtp(true); */}
      {/*                  sendOtp(phoneNumber).subscribe( */}
      {/*                    (res) => { */}
      {/*                      if (res.status === true) { */}
      {/*                        setToastConfig({ */}
      {/*                          ...toastConfig, */}
      {/*                          show: true, */}
      {/*                          text: `OTP sent to ${phoneNumber}`, */}
      {/*                          showTick: true, */}
      {/*                          time: 1500, */}
      {/*                        }); */}
      {/*                      } */}
      {/*                      setStep(steps.OTP_SCREEN); */}
      {/*                      setResendingOtp(false); */}
      {/*                    }, */}
      {/*                    (error) => { */}
      {/*                      Sentry.captureException(error); */}
      {/*                      setResendingOtp(false); */}
      {/*                    }, */}
      {/*                  ); */}
      {/*                } else { */}
      {/*                  setToastConfig({ */}
      {/*                    ...toastConfig, */}
      {/*                    show: true, */}
      {/*                    text: 'Invalid Phone Number', */}
      {/*                    showTick: false, */}
      {/*                  }); */}
      {/*                } */}
      {/*              } : () => {} */}
      {/*          } */}
      {/*            > */}
      {/*              { resendingOtp ? 'Resending OTP...' : 'Resend OTP' } */}
      {/*            </span> */}
      {/*          ) : null} */}
      {/*          {step === steps.ENTER_DETAILS_SCREEN ? ( */}
      {/*            <span> */}
      {/*              By pressing proceed, you agree to be contacted on "WhatsApp" */}
      {/*              regarding service delivery and T&C. */}
      {/*            </span> */}
      {/*          ) : null} */}
      {/*        </span> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </form> */}

      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
