import React, { useState, useEffect } from 'react';
import useStyle from 'isomorphic-style-loader/useStyles';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { BeatLoader, PulseLoader } from 'react-spinners';
import css from './index.css';
import Toast from '../Toast/Toast';
import { sendOtp, verifyOtp } from '../../_services/api/OTPs';
import { saveLoginDetails } from '../../logInUtilities/logInUtilities';
import {
  getAccOtpGtmEvent,
  initiateAccLoginGtmEvent,
  loginGtmEvent,
  resendAccOtpGtmEvent,
  retailGetOtpEvent,
  retailInitiateLogin,
  retailLoginEvent
} from '../../gtm/gtmServices';
import leftArrow from '../../_services/common-assests/arrowLeft.png';
import { initiateTruecaller } from '../../_services/truecaller/initiate';
import { isMalaysiaRequest } from '../../utilities';
import Modal from '../Modal/Modal';
import LoginUpperBar from '../LoginPage/LoginUpperBar';
import { getQueryStringValue } from '../../utilities';
import CountryMaterialInput from '../MaterialInput/CountryMaterialInput';
import history from '../../history';
import MarketingHeader from '../Header/MarketingHeader';
import displayPossibleSctions from '../../reducers/displayPossibleSections';
import secure from '../AccessoriesLoginPopUp/security.png'

const RetailLoginPopup = (props) => {
  useStyle(css);
  const {
    onLoggedIn, hide, loginFor='continue',
  } = props;
  const steps = {
    PHONE_NUMBER_SCREEN: 1,
    OTP_SCREEN: 2,
  };
  const [appear, setAppear] = useState(false);
  const [step, setStep] = useState(steps.PHONE_NUMBER_SCREEN);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1500,
  });
  const [resendButtonDisabledTime, setResendButtonDisabledTime] = useState(30);
  const [showWaitLoader, setShowWaitLoader] = useState(false);
  let resendOtpTimerInterval;
  const accessories = getQueryStringValue('return') && getQueryStringValue('return').includes('accessories');

  const disappear = () => {
    setAppear(false);
    setTimeout(() => {hide();}, 1000);
  };

  useEffect(() => {
    if(props.pushCheckout && props.user?.is_logged_in) history.push('/checkout');
  },[props.user])

  const afterLoggedIn = onLoggedIn ? onLoggedIn : () => {
    const redirectType = getQueryStringValue('type');
    if (getQueryStringValue('return') && getQueryStringValue('return').length > 0) {
      if (redirectType === 'hard' && window?.location) {
        window.location.replace(getQueryStringValue('return'));
      } else history.replace(getQueryStringValue('return'));
    }
  };

  const getSubmitButtonText = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      return 'GET OTP';
    }
    if (step === steps.OTP_SCREEN) {
      return 'VERIFY';
    }
    if (step === steps.USER_DETAILS) {
      return 'SUBMIT';
    }
    return 'GET OTP';
  };
  const mobileInputHandler = (event) => {
    const inputEl = event.target;
    if(isMalaysiaRequest()){
      if (inputEl.value.length > 11) inputEl.value = inputEl.value.slice(0, 11);
    }
    else{
      if (inputEl.value.length > 10) inputEl.value = inputEl.value.slice(0, 10);
    }
    setPhoneNumber(inputEl.value);
  };

  function getOtpInputElement(index) {
    return document.getElementById(`otpInput${index}`);
  }
  const clearOTP = () => {
    const inputElem1 = getOtpInputElement(1);
    const inputElem2 = getOtpInputElement(2);
    const inputElem3 = getOtpInputElement(3);
    const inputElem4 = getOtpInputElement(4);
    if (inputElem1 && inputElem2 && inputElem3 && inputElem4) {
      inputElem1.value = '';
      inputElem2.value = '';
      inputElem3.value = '';
      inputElem4.value = '';
      setOtp('');
    }
  };
  const onKeyUpEvent = (index, event) => {
    const eventCode = event.which || event.keyCode;
    if (getOtpInputElement(index).value.length === 1) {
      if (index !== 4) {
        getOtpInputElement(index + 1).focus();
      } else {
        getOtpInputElement(index).blur();
      }
    }
    if (getOtpInputElement(index).value.length > 1) {
      getOtpInputElement(index).value = getOtpInputElement(index).value.slice(0, 1);
    }
    if (eventCode === 8 && index !== 1) {
      getOtpInputElement(index - 1).focus();
    }
    const input1 = getOtpInputElement(1).value;
    const input2 = getOtpInputElement(2).value;
    const input3 = getOtpInputElement(3).value;
    const input4 = getOtpInputElement(4).value;
    if (input1 && input2 && input3 && input4) {
      const otpValue = `${input1}${input2}${input3}${input4}`;
      setOtp(otpValue);
    } else {
      setOtp('');
    }
  };
  const onFocusEvent = (index) => {
    for (let item = 1; item < index; item += 1) {
      const currentElement = getOtpInputElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  };
  const onOTPBoxValueChange = (event) => {
    const inputEl = event.target;
    if (inputEl.value.length > 1) {
      inputEl.value = inputEl.value.slice(0, 1);
    }
  };
  const sendOTP = (resend) => {
    if (isMalaysiaRequest()) {
      if (isNaN(phoneNumber) || !(phoneNumber.length >= 9 && phoneNumber.length <= 11)) {
        setToastConfig({ ...toastConfig, show: true, text: 'Enter a valid mobile number.' });
        return;
      }
    } else if (isNaN(phoneNumber) || !(phoneNumber.length === 10)) {
      setToastConfig({
        show: true,
        showTick: false,
        text: 'Enter a valid mobile number.',
      });
      return ;
    } 
    {
      retailGetOtpEvent('popuplogin', phoneNumber);
      setWaiting(true);
      sendOtp(phoneNumber).subscribe(
        (res) => {
          if (res.status) {
            if (accessories) {
              if (resend) resendAccOtpGtmEvent('header');
              else getAccOtpGtmEvent('header');
            }
            setWaiting(false);
            setStep(steps.OTP_SCREEN);
            setToastConfig({
              show: true,
              text: `OTP sent to ${phoneNumber}`,
              showTick: true,
              time: 1500,
            });
            setResendButtonDisabledTime(30);
          } else {
            setToastConfig({
              show: true,
              text: res.message || 'Error in sending OTP',
              showTick: true,
              time: 1500,
            });
            setWaiting(false);
          }
        }, (err) => {
          setToastConfig({
            show: true,
            text: err.message || 'Error in sending OTP',
            showTick: true,
            time: 1500,
          });
          setWaiting(false);
        },
      );
    }
  };
  const submitButtonHandler = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      sendOTP();
    } else if (step === steps.OTP_SCREEN) {
      if (isNaN(otp) || otp.length !== 4) {
        setToastConfig({
          show: true,
          showTick: false,
          text: 'Invalid OTP',
        });
      } else {
        setWaiting(true);
        verifyOtp(phoneNumber, otp).subscribe(
          (res) => {
            setWaiting(false);
            if (res.status) {
              saveLoginDetails({ ...res, is_logged_in: true });
              setToastConfig({
                show: true,
                text: 'Logged In',
                showTick: true,
                time: 1500,
              });
              disappear();
              retailLoginEvent(
                res.data.id,
                'popuplogin',
                phoneNumber,
                true,
                false,
              );
              loginGtmEvent({
                phoneNumber, success: true, fire_screen: 'header', user_id: res.data.id,
              });
              if (afterLoggedIn) afterLoggedIn();
            } else {
              clearOTP();
              setToastConfig({
                show: true,
                text: res.message || 'Wrong OTP',
                showTick: false,
                time: 1500,
              });
            }
          }, (err) => {
            setWaiting(false);
            loginGtmEvent({ phoneNumber, success: false });
            retailLoginEvent(
              null,
              'popuplogin',
              phoneNumber,
              false,
              false,
            );
            clearOTP();
            setToastConfig({
              show: true,
              text: err.response.message || 'Verification Failed!',
              showTick: false,
              time: 1500,
            });
          },
        );
      }
    }
    // } else if (step === steps.USER_DETAILS) {
    //   if (userDetails.name && /^[a-zA-Z\s]+$/.test(userDetails.name)
    //     && userDetails.email
    //     && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userDetails.email)) {
    //     setWaiting(true);
    //     updateProfile(userDetails.name, userDetails.email).subscribe((res) => {
    //       if (res.status === true) {
    //         setWaiting(false);
    //         saveLoginDetails({
    //           ...res.data,
    //         });
    //         disappear();
    //         if (onLoggedIn) onLoggedIn();
    //       }
    //     }, (err) => {
    //       setWaiting(false);
    //       setToastConfig({
    //         show: true,
    //         text: err.response.message || 'Error Occured!',
    //         showTick: false,
    //         time: 1500,
    //       });
    //     });
    //   } else if (!userDetails.name || !(/^[a-zA-Z\s]+$/.test(userDetails.name))) {
    //     setToastConfig({
    //       show: true,
    //       showTick: false,
    //       text: 'User name can only have alphabets',
    //     });
    //   } else {
    //     setToastConfig({
    //       show: true,
    //       showTick: false,
    //       text: 'Invalid Email',
    //     });
    //   }
    // }
  };
  const getModalClass = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) return css.mobileScreen;
    if (step === steps.OTP_SCREEN) return css.otpScreen;
    if (step === steps.USER_DETAILS) return css.userDetails;
    return null;
  };
  const startResendOtpTimer = () => {
    if (resendOtpTimerInterval) {
      clearInterval(resendOtpTimerInterval);
    }
    resendOtpTimerInterval = setInterval(() => {
      if (resendButtonDisabledTime <= 0) {
        clearInterval(resendOtpTimerInterval);
      } else {
        setResendButtonDisabledTime(resendButtonDisabledTime - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    if (accessories) initiateAccLoginGtmEvent('header');
    retailInitiateLogin('popuplogin');
    setTimeout(() => setAppear(true), 100);
    if(isMalaysiaRequest())
    return;
    // initiateTruecaller(setShowWaitLoader)
    //   .then(
    //     (res) => {
    //       window.onfocus = {};
    //         saveLoginDetails({
    //           ...res.data,
    //           is_logged_in: true,
    //         });
    //         setToastConfig({
    //           show: true,
    //           text: 'Logged In',
    //           showTick: true,
    //           time: 1500,
    //         });
    //         disappear();
    //         loginGtmEvent({
    //           phoneNumber: res.data.mobile, success: true, fire_screen: 'header', user_id: res.data.id, truecaller: true,
    //         });
    //         retailLoginEvent(
    //           res.data.id,
    //           'popuplogin',
    //           res.data.mobile,
    //           true,
    //           true,
    //         );
    //         if (afterLoggedIn) afterLoggedIn();
    //         if (onLoggedIn) onLoggedIn();
    //     },
    //   )
    //   .catch(
    //     () => {
    //       window.onfocus = {};
    //         setToastConfig({
    //           show: true,
    //           text: 'Please login by mobile number.',
    //           time: 2000,
    //         });
    //         setShowWaitLoader(false);
    //         retailLoginEvent(
    //           null,
    //           'popuplogin',
    //           null,
    //           false,
    //           true,
    //         );
    //     },
    //   );
  }, []);

  useEffect(() => {
    startResendOtpTimer();

    return () => {
      if (resendOtpTimerInterval) {
        clearInterval(resendOtpTimerInterval);
      }
    };
  }, [resendButtonDisabledTime]);

  useEffect(() => {
    if (otp && otp.length === 4) {
      submitButtonHandler();
    }
  }, [otp]);

  useEffect(() => {
    if (isMalaysiaRequest()) {
      if (props.lead && props.lead.mobile
        && (!(isNaN(props.lead.mobile)))
        && (props.lead.mobile.length === 9 || props.lead.mobile.length === 10 || props.lead.mobile.length === 11) && !phoneNumber.length) {
        setPhoneNumber(props.lead.mobile);
      }
    } else if (props.lead && props.lead.mobile
        && (!(isNaN(props.lead.mobile)))
        && props.lead.mobile.length === 10 && !phoneNumber.length) {
      setPhoneNumber(props.lead.mobile);
    }
  }, []);

  useEffect(() => {
    if ('OTPCredential' in window) {
      const abortController = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: abortController.signal,
      }).then((otpData) => {
        setStep(steps.OTP_SCREEN);
        const tempOtp = otpData.code.toString();
        const input1 = getOtpInputElement(1);
        const input2 = getOtpInputElement(2);
        const input3 = getOtpInputElement(3);
        const input4 = getOtpInputElement(4);
        [input1.value, input2.value, input3.value, input4.value] = tempOtp;
        setOtp(tempOtp);
        abortController.abort();
      }).catch((err) => console.log('OTP error check', err));
    }
  }, [phoneNumber]);
  return (
    <>
      {showWaitLoader
      && (
        <Modal
          noPadding
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            fontWeight: 500,
          }}
          >
            <div style={{
              marginBottom: '50px',
            }}
            >
              {' '}
              Please hold on while we get your details.
            </div>
            <BeatLoader
              size={15}
              color="#e73c33"
            />
          </div>
        </Modal>
      )}
      <div
        className={classnames(css.loginOverlay, appear ? css.loginOverlayAppear : null)}
        onClick={() => disappear()}
      />
      <div
        className={classnames(
          css.loginModal,
          appear ? css.loginModalAppear : null,
          getModalClass(),
        )}
        style={!props.context?.pathname.includes('login') ? {} : {height: '100%', transition: 'none', borderRadius: '0'}}
      >
        {step === steps.PHONE_NUMBER_SCREEN && (
          <>
            {!props.context?.pathname.includes('login') ? 
            null 
          :
          <>
          <div style={{margin: '-1.5rem -1rem 1rem'}}>
            <MarketingHeader/>
          </div>
          
        </>
          //       <>
          //   <div className={css.heading}>
          //     {`Login/Signup to ${loginFor}`}
          //   </div>
          //   <div className={css.inputContainer}>
          //     <div className={css.countryCode}>+91</div>
          //     <input
          //       type="number"
          //       id="userMobileNo"
          //       placeholder="Enter your mobile number"
          //       value={phoneNumber}
          //       onChange={mobileInputHandler}
          //     />
          //   </div>
          // </>
          }
          <LoginUpperBar 
            title="Login"
            subtitle="Enter your phone number to continue"
            noBackButton
            logo
            upperBarContainerStyles={{backgroundColor: 'white', padding: '0 0 1rem'}}
          />
          <CountryMaterialInput
            inputProps={{
              type: 'tel',
              maxLength: isMalaysiaRequest() ? '11' : '10',
              value: phoneNumber,
            }}
            onChange={mobileInputHandler}
            placeholder="Phone number"
          />
          </>
        )}
        {step === steps.OTP_SCREEN && (<>
          {history?.location?.pathname === '/login' ? 
            <MarketingHeader/>
          : null}
          <div style={{ paddingBottom: '16px', width: '100%', backgroundColor: '#fff' }}>
            <div className={css.heading}>
              <img src={leftArrow} alt="back" onClick={() => setStep(steps.PHONE_NUMBER_SCREEN)} />
              Verify OTP
            </div>
            <div className={css.displayText1}>
              We have sent a 4 digit OTP on
            </div>
            <div className={css.displayText2}>
              {props.isMalaysiaRequest ? `+60 - ${phoneNumber}` : `+91 - ${phoneNumber}`}
            </div>
            <div className={css.otpInput}>
              <input id="otpInput1" type="tel" maxLength="1" onKeyUp={(e) => onKeyUpEvent(1, e)} onFocus={() => onFocusEvent(1)} onChange={onOTPBoxValueChange} />
              <input id="otpInput2" type="tel" maxLength="1" onKeyUp={(e) => onKeyUpEvent(2, e)} onFocus={() => onFocusEvent(2)} onChange={onOTPBoxValueChange} />
              <input id="otpInput3" type="tel" maxLength="1" onKeyUp={(e) => onKeyUpEvent(3, e)} onFocus={() => onFocusEvent(3)} onChange={onOTPBoxValueChange} />
              <input id="otpInput4" type="tel" maxLength="1" onKeyUp={(e) => onKeyUpEvent(4, e)} onFocus={() => onFocusEvent(4)} onChange={onOTPBoxValueChange} />
            </div>
            {!resendButtonDisabledTime ? (
              <div
                className={css.secondaryBtn}
                onClick={() => {
                  sendOTP(true);
                }}
              >
                Resend OTP
              </div>
            ) : (
              <div className={css.resendText}>{`Resend OTP in ${resendButtonDisabledTime} seconds`}</div>
            )}
          </div>
          </>
        )}
        <div className={css.buttonDiv}>
          {/* <div className={css.footerNote}>Sign in to access your orders, offers &amp; Wallet.</div> */}
          <button
            className="submitBtn"
            type="button"
            onClick={waiting ? null : submitButtonHandler}
            // style={phoneNumber.length !== 10 ? { background: 'rgba(231, 60, 51, 0.54)' } : null}
            style={isMalaysiaRequest() && phoneNumber.length < 9 ? { background: 'rgba(231, 60, 51, 0.54)' } : !isMalaysiaRequest() && phoneNumber.length < 10 ? { background: 'rgba(231, 60, 51, 0.54)' } : null}
          >
            {waiting ? <PulseLoader color="#FFF" size={10} /> : getSubmitButtonText()}
          </button>
        </div>
        {accessories ? (
          <div className={css.footerNote}>
            <div className={css.icon}>
              <img src={secure} alt="secure" />
            </div>
            <div className={css.noteText}>
              All your data is secure within GoMechanic ecosystem
            </div>
          </div>
        ) : null}
      </div>
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RetailLoginPopup);
