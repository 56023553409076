import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const paytmTokenFromAuthId = (authId) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `/api/v1/get-paytm-access-token/?authId=${authId}`;
  return HttpHandler.get(url, headers);
};
