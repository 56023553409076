import HttpHeader from '../../httpHeader';
import { Http } from '../../../modules/http';


export const getTruecallerUserApi = (requestId) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `/api/v1/truecaller/get-user/?requestId=${requestId}`;
  return HttpHandler.get(
    url,
    headers,
  );
};
